<!-- 关于我们-->
<template>
  <div class="about">
    <div class="about-header">
      <div class="header-title">关于我们</div>
      <div class="about-main">
        <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/8356ac3bfd42467b8764851520aa1e68.png" alt="">
        <div class="main-right">
          <div class="right-title">广州市农药肥料应用协会</div>
          <div class="right-text" v-if="windowWidth >780">
            广州市农药肥料应用协会，成立于2016年，经广州市社会组织管理局批准成立，<br>
            协会受广东省农农业农村厅、广州市农业农村局领导。
          </div>
          <div class="right-text" v-else>
            广州市农药肥料应用协会，成立于2016年，经广州市社会组织管理局批准成立，协会受广东省农农业农村厅、广州市农业农村局领导。
          </div>
          <div class="right-block">
            <div class="block-left">服务范围</div>
            <div class="block-right">广东省粤港澳大湾区</div>
          </div>
          <div class="right-block">
            <div class="block-left">服务对象</div>
            <div class="block-right">广大农药、肥料生产经营单位</div>
          </div>
          <div class="right-block right-block-footer">
            <div class="block-left">协会宗旨</div>
            <div class="block-right">以农为本、专业服务、 安全生产、高质量发展</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 协会架构 -->
    <div class="architecture">
      <div class="archite-header">
        <div class="header-title">协会架构</div>
        <div class="header-text">广州市农药肥料应用协会的架构主要包括会员代表大会、理事会和监事会。</div>
      </div>
      <div class="archit-main">
        <div>
          <img v-if="windowWidth >780" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/275987f7c5884fb3b560c0449edc71d6.png" alt="">
          <img v-else src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/0b0e06a8e9304fa9b98b926e42e8b430.png" alt="">
        </div>
      </div>
    </div>
    <!-- 协会服务 -->
    <div class="association">
      <div class="serve">
        <div class="serve-title">协会服务</div>
        <div class="serve-main">
          <div class="main main-one">
            <div class="main-img">
              <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/f5d781e30af8490ea5769a0dc76d155e.png"
                alt="">
            </div>
            <div>
              <div class="main-title">一体化管理</div>
              <div class="main-text">
                <p>农资实体店+小程序店，</p>
                <p>软/硬件专业配套服务</p>
              </div>
            </div>
          </div>
          <div class="main main-two">
            <div class="main-img ">
              <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/4d2f0d889a3b4bd3be468a809b5de774.png"
                alt="">
            </div>
            <div>
              <div class="main-title">数字化服务</div>
              <div class="main-text">
                <p>农资实体店，进销存收银</p>
                <p>台账数据自动采集，实现</p>
                <p>数字化升级服务</p>
              </div>
            </div>
          </div>
          <div class="main main-three">
            <div class="main-img">
              <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/90ca6a9c055943f6baef1007b3909edc.png"
                alt="">
            </div>
            <div>
              <div class="main-title">证件办理</div>
              <div class="main-text">
                <p>协助办理农药经营证件，</p>
                <p>药害、肥害纠纷代理鉴定</p>
              </div>
            </div>
          </div>
          <div class="main main-four">
            <div class="main-img">
              <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/ae13e264748242e79d42ee2bc56aca2d.png"
                alt="">
            </div>
            <div>
              <div class="main-title">纠纷协调</div>
              <div class="main-text">
                <p>药害肥害纠纷协调处理</p>
              </div>
            </div>
          </div>
          <div class="main main-five">
            <div class="main-img">
              <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/4c580d79fe7b46b38603fe984080288f.png"
                alt="">
            </div>
            <div>
              <div class="main-title">咨询服务</div>
              <div class="main-text block_text">
                <p>《农药经营许可证》咨询</p>
                <p>服务</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 特聘顾问单位 -->
    <div class="adviser">
      <div class="adviser-title">特聘顾问单位</div>
      <div class="adviser-main">
        <div class="main-block">· 广东省农科院植保所</div>
        <div class="main-block block2">· 广州汇标检测技术中心</div>
        <div class="main-block block3">· 广东省质量监督化肥农药检验站</div>
        <div class="main-block block4">· 广州华鑫检测技术有限公司</div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="contact">
      <div class="contact-header">
        <div class="header-title">联系我们</div>
        <div class="header-line"></div>
      </div>
      <div class="contact-main">
        <div class="main-left">
          <div class="left-title">联系方式</div>
          <div class="left-text">
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/ba91b662f8a44debb24eb0449031043c.png" alt="">
            文经理 13422276741(微信同号)
          </div>
          <div class="left-text">
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/b607182de9c04f598450ccd2cadb86b8.png" alt="">
            407412622@qq.com
          </div>
          <div class="left-text">
            <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/a2d4321eaf4c457c97cbde7dbfdf407b.png" alt="">
            广州市黄埔区科学大道112号绿地中央广场C1栋
          </div>
        </div>
        <div class="main-right">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/3d68a022e5344b5eb95e5f49b7991e80.png" alt="">
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'solution',
  data () {
    return {
      bannerUrl1: '',
      bannerUrl2: '',
      windowWidth: 1024
    }
  },
  mounted () {
    this.windowWidth = window.document.body.clientWidth
    window.addEventListener('resize', this.resizePage)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizePage)
  },
  methods: {
    // 页面大小改变
    resizePage () {
      this.windowWidth = window.document.body.clientWidth
    }
  }
}
</script>

<style scoped lang="scss">
.about {
  margin-top: 68px;
  font-family: PingFang SC, PingFang SC-400;
  font-weight: 400;

  .about-header {
    padding-top: 39px;
    margin-bottom: 75px;

    .header-title {
      font-size: 32px;
      color: #000000;
      line-height: 38px;
      text-align: center;
    }
  }

  .about-main {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    margin-top: 28px;

    img {
      width: 536px;
      height: 228px;
    }

    .main-right {
      margin-left: 50px;

      .right-title {
        padding-top: 10px;
        font-size: 28px;
        color: #000000;
      }

      .right-text {
        padding-top: 12px;
        font-size: 16px;
        color: #000000;
        line-height: 24px;
        font-family: PingFang SC, PingFang SC-400;
        margin-bottom: 26px;
      }

      .right-block {
        height: 32px;
        display: flex;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        align-items: center;
        color: #000000;

        .block-left {
          width: 80px;
          height: 22px;
          background: #0c855a;
          color: #fff;
          line-height: 22px;
          text-align: center;
          margin-right: 20px;
        }
      }
    }
  }

  .architecture {
    width: 1200px;
    margin: 0 auto;
    margin-top: 75px;

    .archite-header {
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      color: #000000;

      .header-title {
        height: 42px;
        text-align: center;
        line-height: 42px;
        font-size: 32px;
      }

      .header-text {
        margin-top: 9px;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
      }
    }

    .archit-main {
      margin-top: 35px;
      margin-bottom: 71px;
      div {
        width: 502px;
        height: 176px;
        margin: 0 auto;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .association {
    width: 100%;
    height: 424px;
    background: #f2fbf8;

    .serve {
      height: 432px;

      .serve-title {
        width: 1200px;
        font-size: 32px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        color: #333333;
        line-height: 38px;
        text-align: center;
        margin: 0 auto;
        padding-top: 50px;
        margin-bottom: 33px;

      }

      .serve-main {
        width: 1200px;
        margin: 0 auto;
        display: flex;

        // justify-content: space-between;
        .main-one {
          margin-left: 26px;
        }

        .main-two {
          width: 176px;
          margin-left: 71px;
        }

        .main-three {
          width: 176px;
          margin-left: 68px;
        }

        .main-four {
          width: 160px;
          margin-left: 71px;
        }

        .main-five {
          width: 176px;
          margin-left: 81px;
        }

        .main {
          display: flex;
          flex-direction: column;
          align-items: center;

          // width: 170px;
          .main-img {
            width: 113px;
            height: 113px;
          }

          .main-title {
            font-size: 18px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            margin-top: 26px;
          }

          .main-text {
            // height: 48px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-400;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            white-space: nowrap;
            margin-top: 16px;
          }
        }
      }
    }
  }
  .adviser{
    width: 1200px;
    margin: 0 auto;
    margin-top: 43px;
    .adviser-title{
      text-align: center;
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: CENTER;
      color: #333333;
      line-height: 38px;
      margin-bottom: 46px;
    }
    .adviser-main{
      padding: 0 25px 0 48px;
      display: flex;
      .main-block{
        width: 205px;
        height: 85px;
        background: #f7f7f7;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: center;
        color: #000000;
        line-height: 85px;
      }
      .block2{
        margin-left: 59px;
      }
      .block3{
        width: 267px;
        margin-left: 71px;
      }
      .block4{
        width: 249px;
        margin-left: 71px;
      }
    }
  }
  .contact{
    width: 1200px;
    margin: 0 auto;
    .contact-header{
      margin-top: 84px;
      .header-title{
        font-size: 32px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: CENTER;
        color: #333333;
        line-height: 38px;
        margin-bottom: 35px;
      }
      .header-line{
        width: 100%;
        height: 0px;
        background: #d9d9d9;
        border: 1px solid #f0f0f0;
        margin-bottom:35px;
      }
    }
    .contact-main{
      display: flex;
      margin-bottom: 77px;
      .main-left{
        padding: 45px 145px 0 64px ;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        color: #333333;
        .left-title{
          color: #000;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 16px;
        }
        .left-text{
          font-size: 14px;
          line-height: 16px;
          padding-bottom: 11px;
        }
      }
      .main-right{
        img{
          width: 612px;
          height: 218px;
        }
      }
    }
  }
}
</style>

<style scoped lang='scss'>
// 手机端 样式
@import "./media";
</style>
